@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'nprogress/nprogress.css';
@import '@fortawesome/fontawesome-svg-core/styles.css';

#nprogress .bar {
    background: limegreen !important;
}

.disabled-input {
    background: white !important;
    color: black !important;
}

.ant-radio-checked .ant-radio-inner:after{
  background-color: rgba(148, 138, 84);
  border-color: rgba(148, 138, 84) !important ;
}

/* Gaya khusus untuk menghilangkan titik di dalam radio button dan mengisi warna latar belakang secara penuh */
.ant-radio-inner:after {
  background-color: rgba(148, 138, 84) !important;
}

/* Gaya khusus untuk latar belakang radio button */
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: rgba(148, 138, 84) !important;
  border-color: rgba(148, 138, 84) !important;
}

/* Customize the background color when a step is active or completed */
.ant-steps-item.ant-steps-item-process .ant-steps-item-icon,
.ant-steps-item.ant-steps-item-finish .ant-steps-item-icon {
  background-color: rgba(148, 138, 84);
  border-color: rgba(148, 138, 84);
}

/* Customize the background color of the step icons */
.ant-steps-item-icon {
  background-color: rgba(148, 138, 84); 
}

/* Customize the color of the checkmark icon */
.ant-steps-item-icon .anticon-check {
  color: white; 
}

/* Customize table header color */
.ant-table .ant-table-container .ant-table-content table thead.ant-table-thead .ant-table-cell {
  background-color: #948A5480;
}

:where(.css-dev-only-do-not-override-1vtf12y).ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: rgba(148, 138, 84);
  border-color: rgba(148, 138, 84);
}
